import { TypoMBold } from '@atoms/Typos';
import styles from './css';

type Props = {
  heading: string;
};

const JpSectionHeader = ({ heading }: Props) => (
  <div className="section-header">
    <h2 className="section-header-heading">
      <TypoMBold text={heading} />
    </h2>
    <style jsx={true}>{styles}</style>
  </div>
);

export default JpSectionHeader;
