import css from 'styled-jsx/css';

const styles = css`
  :global(.no-fill) {
    :global(path) {
      fill: none;
    }
  }
`;

export default styles;
